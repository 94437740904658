import React from "react"
import { graphql } from "gatsby"
import SEO from "components/Seo"
import classNames from "classnames/bind"
import styles from "./landplots.module.css"
import { Helmet } from "react-helmet"
import { client, isTablet } from "lib/helpers"
import Areas from "components/Map"
import LogoBig from "images/logo-big.svg"
import Scroll from "images/scroll.svg"
import LandplotList, { LandplotInfo } from "components/LandplotList"

const cx = classNames.bind(styles)

const LandplotsPage = ({ data }) => {
  const landplotsInitial = data.allSanityLandplot.edges.map(
    landplot => landplot.node
  )
  // const [landplots, setLandplots] = React.useState([])
  const [landplots, setLandplots] = React.useState(landplotsInitial)
  const infoEl = React.useRef(null)
  const areasRef = React.useRef()
  const hasLandplotSet = React.useRef(false)
  const [showInfo, setShowInfo] = React.useState(false)
  const [currentLandplotId, setCurrentLandplotId] = React.useState(null)
  const [currentLandplot, setCurrentLandplot] = React.useState(null)
  // const [openedListItem, setOpenedListItem] = React.useState(null)

  const query =
    '*[_type=="landplot" && status != "Išjungtas"] | order(number asc) { id, price, area, number, status, "image": mainImage.asset->url}'

  React.useEffect(() => {
    const areasElRect = areasRef.current.getBoundingClientRect()
    areasRef.current.scrollLeft = (areasElRect.width / 2) * -1

    const fetchData = async () => {
      try {
        const res = await client.fetch(query)

        if (res && res.length) {
          setLandplots(res)
        }
      } catch (error) {
        console.error("err: ", error.message)
      }
    }

    fetchData()
  }, [])

  React.useEffect(() => {
    if (!hasLandplotSet.current) {
      hasLandplotSet.current = true
      return
    }

    const landplot = landplots.find(
      landplot => landplot.number === currentLandplotId
    )

    if (landplot) {
      setCurrentLandplot(landplot)
    }
  }, [currentLandplotId, landplots])

  const handleMouseEnter = e => {
    setShowInfo(true)
  }

  const handleMouseMove = e => {
    const offset = 5
    const windowWidth =
      window.innerWidth || document.documentElement.clientWidth
    let el
    let x = e.clientX + offset
    let y = e.clientY + offset
    // requestAnimationFrame(() => handeMouseMoveF());

    if (showInfo && infoEl.current) {
      el = infoEl.current.getBoundingClientRect()

      if (x + el.width >= windowWidth) {
        x = windowWidth - el.width
      }

      infoEl.current.style.transform = `translateX(${x}px) translateY(${y}px)`
    }
  }

  const handleMouseLeave = e => {
    setShowInfo(false)
  }

  // const handleOpenedItem = id => {
  //   if (openedListItem === id) {
  //     setOpenedListItem(null)
  //   } else {
  //     setOpenedListItem(id)
  //     scroll(id)
  //   }
  // }

  const getLandplot = id => {
    if (id) {
      setCurrentLandplotId(+id)
    }
  }

  // const scroll = id =>
  //   document
  //     .getElementById(`landplot-${id}`)
  //     .scrollIntoView({ behavior: "smooth", block: "center" })

  return (
    <>
      <Helmet
        bodyAttributes={{
          class: "page--landplots",
        }}
      />
      <SEO title={data.sanityLandplots.title} />
      <div className={cx("wrapper")}>
        <LogoBig className={cx("logo")} preserveAspectRatio="xMinYMin meet" />

        <div className={cx("landplots-wrap")} ref={areasRef}>
          <div className={cx("landplots-inner")}>
            <img
              src="/images/sklypai-back.jpg"
              className={cx("areas-background")}
              alt="map background"
            />
            <Areas
              landplots={landplots}
              mouseEnter={handleMouseEnter}
              mouseMove={handleMouseMove}
              mouseLeave={handleMouseLeave}
              // setOpened={handleOpenedItem}
              setLandplot={getLandplot}
              className={cx("landplots")}
            />
          </div>
        </div>
        <div className={cx("scroll-wrapper")}>
          <Scroll className={cx("scroll")} />
        </div>
        {showInfo && currentLandplot && !isTablet() && (
          <LandplotInfo forwardedRef={infoEl} landplot={currentLandplot} />
        )}
        <LandplotList
          landplots={landplots}
          // handleOpenedItem={handleOpenedItem}
          // opened={openedListItem}
        />
      </div>
    </>
  )
}

export const query = graphql`
  {
    sanityLandplots {
      id
      title
    }

    allSanityLandplot(
      sort: { fields: [number], order: ASC }
      filter: { status: { ne: "Išjungtas" } }
    ) {
      edges {
        node {
          id
          number
          price
          area
          status
          mainImage {
            asset {
              id
              fluid(maxWidth: 700) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`

export default LandplotsPage
