module.exports = {
  list: [
    {
      id: 17,
      d:
        "M563.384663 546.907769l15.053402 2.762957 33.167878-3.10365 11.024143-4.980108-33.438728 211.734701-123.85076 1.50341 41.500338-213.238111 7.046457 1.360408 12.428054 1.34274 8.763315 2.617653z",
    },
    {
      id: 16,
      d:
        "M589.191358 753.321669l33.438728-211.734701 15.619529-7.870916 13.036521-6.389285 22.047334-4.56224 18.343519-6.19188 17.022216-7.013558 22.529996-11.453873-20.264696 248.087192z",
    },
    {
      id: 15,
      d:
        "M829.108451 739.704932l10.307528-246.939474-27.058685 1.745291-19.343765-1.745291-61.784328 5.339758-20.264696 248.087192z",
    },
    {
      id: 2,
      d:
        "M2157.05519 306.010756l-70.57591-85.724006 53.63783-18.7742-15.05942-23.682338-6.71794-10.037913-6.70721-12.49174-9.19854-14.199842-8.7737-14.58921-13.18132-17.895882-22.87317-15.0744569-14.1504-12.9277984-8.21975-6.5176076-12.52048-1.9307138-11.63033 11.8233802-23.52301 17.1423365-43.37607 40.562253 132.21916 173.472164z",
    },
    {
      id: 6,
      d:
        "M1742.80081 358.442944l87.92161-14.863673-104.24196-172.804352-6.62617 4.303382-17.14998 12.755323-16.79507 21.812158-6.04683 4.453557-15.73339 9.369404z",
    },
    {
      id: 8,
      d:
        "M1573.96765 389.374635l33.56165-8.196092 44.69912-8.136099-76.69868-144.864754h-10.44973l-6.61154 3.349128H1505.79025l-12.96159 5.586439z",
    },
    {
      id: 10,
      d:
        "M1412.20611 481.772998l30.29492-7.957645 39.5949-45.733918-106.22399-179.148353-4.30816 1.54772-17.04658 9.123543-7.01958 11.328928-17.24067 18.827003-14.5984 10.731367z",
    },
    {
      id: 23,
      d:
        "M2099.97688 531.55737l-102.9771 46.244264-161.85854-215.990928 79.65192-12.533214 29.39261-5.698221z",
    },
    {
      id: 24,
      d:
        "M1996.99978 577.801634l-21.14955 12.66488-89.48729 51.54552-159.88248-263.807617 108.66078-16.393711z",
    },
    {
      id: 20,
      d:
        "M2222.54161 307.453236l23.59326-4.897488L2344 422.174117l-52.54575 25.298163-127.73877-135.118112z",
    },
    {
      id: 12,
      d:
        "M1220.86463 636.034785c-.78532 10.300414-1.74258 18.10432-2.87176 23.411718-1.12919 5.307398-2.95161 10.259017-5.46726 14.854856l-9.8674 10.591681-115.48411 19.37874-40.0038-261.492884 8.23555-.827921 6.30807-4.882255 25.83482-20.739333 13.64842-1.371123 51.61568-12.582995 29.81743-13.154179 47.18279-31.224727 86.98106 153.686469-68.69844 28.769064c-5.77977 4.675129-9.69827 8.565427-11.7555 11.670896-2.05724 3.105469-3.55511 7.391385-4.49362 12.857748h0l-10.98193 71.054245z",
    },
    {
      id: 4,
      d:
        "M1993.94029 324.423102l-102.19241-138.796501-12.91322 7.716183-25.67911-.450604-23.41381-5.710137-21.737-9.351831 16.73385 32.331472 23.32012 37.070336 66.73445 86.180677z",
    },
    {
      id: 14,
      d:
        "M839.415979 492.765458l28.805468 11.75985 28.049233-3.255756 9.578913-2.375951 17.512561-12.983559.241129-4.849949 4.515331-8.499721 9.73603-5.246924 10.467396-2.09955 3.654237-2.981917-1.59658 264.470538-121.271246 13.002413z",
    },
    {
      id: 13,
      d:
        "M951.976277 462.231981l11.60163.452966 18.86206 5.902124 12.222219-1.695769 21.417424-14.379384 29.19497-10.143121 40.39801 263.67415-135.292893 20.659572z",
    },
    {
      id: 18,
      d:
        "M506.840936 541.586968l-41.500338 213.238111L333 758.928681l58.014134-225.93477 24.489475 3.897064h34.4136c3.458366 1.551009 7.574051 2.326514 12.347054 2.326514h33.38447l11.192203 2.369479z",
    },
    {
      id: 37,
      d:
        "M1073.70741 843.953107l-10.33117 80.223315-23.21664 108.903418-233.790404 28.62142 14.655667-191.350821z",
    },
    {
      id: 34,
      d:
        "M1098.22361 822.876014l256.24702-38.503268L1338.5 915.312952l-252.26361 22.423566 9.4551-53.093061z",
    },
    {
      id: 27,
      d:
        "M1219.10433 706.875354l27.95216-54.085578 11.13685-91.564171 111.57394-48.288229 44.22305-13.0462 23.74312 25.545976 55.23852 57.24251-98.02602 68.280644v11.654281z",
    },
    // {
    //   id: 28,
    //   d:
    //     "M1215.99134 707.991953l28.01853-54.052986 11.1633-91.508994 111.83886-48.25913 44.32805-13.038338 23.7995 25.530582 56.33827 51.138547-19.82583 104.309714-78.39441-17.79224z",
    // },
    {
      id: 26,
      d:
        "M1413.18138 501.027242l48.62109-10.951566 31.1695-30.978087 17.76319-24.609784 100.3355-36.136037 107.32547 244.825416 22.18623 38.230438-36.13711-7.286528-66.13102-10.959048-36.41245-17.423769-28.23025-31.120856-80.69956-31.937759z",
    },
    // {
    //   id: 27,
    //   d:
    //     "M1411.34008 501.132505l48.83265-11.02261 31.30512-31.179048 17.84048-24.769432 100.77207-36.370458 124.37457 284.320391-97.01247-17.79224-36.57088-17.5368-28.35308-31.322743-81.05069-37.657931z",
    // },
    {
      id: 21,
      d:
        "M2170.45739 319.400526l120.99686 128.071754-93.8277 39.348836-139.36756-153.408419z",
    },
    // {
    //   id: 32,
    //   d:
    //     "M1503.26383 960.888712l236.52213 46.686118L1727.59247 1196l-295.16755-97.17655 39.2271-33.96423 14.76073-22.26681z",
    // },
    {
      id: 31,
      d:
        "M1643.19376 901.667472l11.30694-58.81736 96.59847 19.471443-15.57743 309.780685-9.14022 103.235-225.38548-159.954 11.23542-59.62889 64.57101-83.412454z",
    },
    {
      id: 29,
      d:
        "M1357.17256 782.157352l26.60048-81.796622 149.00558-2.597887 223.257 38.817693-4.93645 125.741019z",
    },
    // {
    //   id: 33,
    //   d:
    //     "M1354.47063 784.372746l38.78698-120.053638 149.97705 36.1062-18.37337 119.468515z",
    // },
    // {
    //   id: 30,
    //   d:
    //     "M1524.86129 819.893823l18.37337-119.468515 207.43624 38.232125-21.57522 125.340641z",
    // },
    {
      id: 28,
      d:
        "M1532.66395 696.492921l-104.47111 3.888248h-44.6322l11.38531-37.562912v-11.857951l96.81633-68.280644 82.11175 31.502599 28.32489 31.179432 36.53453 17.456564 101.84891 18.589365 17.65616 30.412526-2.2029 24.760388z",
    },
    // {
    //   id: 29,
    //   d:
    //     "M1531.27746 698.148552l-59.62544-16.037204 19.82583-104.309714 81.05069 37.657931 28.35308 31.322743 36.57088 17.5368 88.2347 14.904434 8.77777 2.887806 16.20593 56.546085z",
    // },
    {
      id: 33,
      d:
        "M1086.23639 937.736518l253.75215-22.423566-38.8761 142.877018-82.87936-16.04093-152.39542-9.63305z",
    },
    // {
    //   id: 13,
    //   d:
    //     "M1085.32221 706.310692l-40.04763-263.941895 8.24458-.835675 6.31498-4.927979 25.48807-18.055889 69.17624 131.461309 75.93812 16.610428-11.27734 70.813633c-.78618 10.396882-1.74449 18.273876-2.87491 23.63098-1.13043 5.357105-2.95484 10.355098-5.47325 14.99398l-9.87821 10.690877-115.61065 19.560231z",
    // },
    {
      id: 22,
      d:
        "M2099.97688 531.55737l-155.79111-187.978099 114.07322-10.166574 139.36756 153.408419z",
    },
    {
      id: 11,
      d:
        "M1315.75087 514.170843l14.22075-8.588459 63.3541-20.667045 18.01436-2.822495-95.58921-182.369778-38.35847 29.287333-38.91684 25.016552-10.07906 5.553344z",
    },
    {
      id: 9,
      d:
        "M1482.09593 428.081435l34.70377-21.13448 57.16795-17.57232-81.13899-152.261378-21.60108 6.404939-24.15261.594913-29.75008 3.7247-19.11396-1.63864-11.23523.007636-11.10376 2.726277z",
    },
    {
      id: 7,
      d:
        "M1652.25326 373.042444l90.54755-14.5995-78.67179-134.974201-8.78689 5.353786h-9.78838l-13.50787 4.70537-19.59231-2.475171-18.54031 1.905537-18.38352-4.780575z",
    },
    {
      id: 5,
      d:
        "M1830.72242 343.579271l65.26059-7.145166 18.81015-3.021408-66.73445-86.180677-23.32012-37.070336-16.73385-32.331472h-21.53678l-26.60377-12.91449-20.90877-2.761712-12.47496 8.620909z",
    },
    {
      id: 3,
      d:
        "M1993.94029 324.423102l82.46464-9.25792-132.21916-173.472164-18.73118 20.460992-15.89853 11.251681-14.75575 10.426883-3.05243 1.794027z",
    },
    {
      id: 1,
      d:
        "M2246.13487 302.555748l-69.43578-82.268998-24.86357-5.791438-9.21328-7.31197-2.50513-5.670792-26.27779 9.382615-27.36004 9.391585 77.2362 92.067418 58.82613-4.900932z",
    },
    {
      id: 25,
      d:
        "M1700.05645 603.098166c11.44855 26.651972 30.84258 62.892633 58.18207 108.721982l127.76144-71.401691L1726.331 377.523172l-115.16498 19.518803c45.40229 105.109693 75.03243 173.795089 88.89043 206.056191z",
    },
    // {
    //   id: 31,
    //   d:
    //     "M1524.86129 819.893823l204.23439 44.104251 10.69028 143.070126-236.52213-46.179488z",
    // },
    {
      id: 32,
      d:
        "M1341.29254 913.859532l161.76344 41.438041 50.79869 9.263675-64.6812 79.805032-7.18901 24.05465-14.10611 36.655-80.48157-26.81312-84.9305-22.50748z",
    },
    // {
    //   id: 35,
    //   d:
    //     "M1339.98854 915.312952l163.27529 45.57576-16.85108 81.703698-14.76073 22.26681-39.2271 33.96423-46.27296-17.97035-85.03952-22.66313z",
    // },
    {
      id: 30,
      d:
        "M1357.23157 783.680804l275.32781 54.19391-2.04921 31.339699-9.57518 20.836247-67.08032 74.510588-211.00994-50.514901z",
    },
    // {
    //   id: 34,
    //   d:
    //     "M1354.47063 784.372746l170.39066 35.521077-21.59746 140.994889-163.27529-45.57576z",
    // },
    {
      id: 36,
      d:
        "M829.108451 764.316252l244.598959-31.869435v111.50629l-252.682547 24.707988z",
    },
    {
      id: 35,
      d:
        "M1221.1942 707.991953l-126.43207 21.170093 3.46148 93.713968 256.24702-38.503268 38.78698-120.053638z",
    },
    {
      id: 38,
      d:
        "M1228.5 1072.5L1098.5 1059.5L1069 1354.5L1114 1365L1119 1344.5L1205.5 1320L1228.5 1072.5Z",
    },
    {
      id: 39,
      d: "M1365 1105.5L1228.5 1073L1206 1320L1340 1336L1365 1105.5Z",
    },
    {
      id: 40,
      d: "M1502.5 1149.5L1365.5 1106L1340.5 1336L1491 1343.5L1502.5 1149.5Z",
    },
    {
      id: 41,
      d:
        "M1712 1304L1502.5 1149.5L1491.5 1342.5C1491.5 1342.5 1556 1344.06 1585 1365C1614 1385.94 1611.5 1453 1611.5 1453L1704.5 1467.5L1712 1304Z",
    },
  ],
}
