import React from "react"
import classNames from "classnames/bind"
import styles from "./styles.module.css"
import areas from "./areas"
import useWindowSize from "hooks/useWindowSize"
import { navigate } from "gatsby"

const cx = classNames.bind(styles)

const Map = ({
  mouseEnter,
  mouseLeave,
  mouseMove,
  setLandplot,
  setOpened,
  landplots,
}) => {
  const [current, setCurrent] = React.useState(null)
  const windowSize = useWindowSize()
  const smallScreen = windowSize.width < 768

  React.useEffect(() => {
    setLandplot(current)
  }, [current])

  const handleMouseEnter = e => {
    setCurrent(e.currentTarget.dataset.id)
  }

  const handleMouseEnterArea = e => {
    mouseEnter()
  }

  const handleMouseMove = e => {
    mouseMove(e)
  }

  const handleMouseLeaveArea = e => {
    setCurrent(null)
    mouseLeave()
  }

  const handleOnClick = id => {
    navigate(`/sklypai/nr-${id}`)
    // setOpened(id)
  }

  return (
    <>
      <svg
        preserveAspectRatio="xMinYMin meet"
        xmlns="http://www.w3.org/2000/svg"
        // viewBox="0 0 1643 1192"
        viewBox="0 0 2500 1534"
        className={cx("wrapper")}
      >
        <g
          fill="none"
          fillRule="evenodd"
          stroke="none"
          strokeWidth="2"
          className={cx("areas")}
        >
          <g
            onMouseMove={handleMouseMove}
            onMouseLeave={handleMouseLeaveArea}
            onMouseEnter={handleMouseEnterArea}
          >
            {areas.list.map(item => {
              const current = landplots.find(
                landplot => landplot.number === item.id
              )

              const pathClass =
                current !== undefined && current.status
                  ? current.status
                  : "Laisvas"

              return (
                <path
                  id={item.id}
                  key={item.id}
                  data-id={item.id}
                  className={cx(pathClass)}
                  stroke="#878A7C"
                  d={item.d}
                  onMouseEnter={handleMouseEnter}
                  onClick={() => handleOnClick(item.id)}
                ></path>
              )
            })}
          </g>
        </g>
      </svg>
    </>
  )
}

export default Map
